<div class="container-fluid">
  <div class="row">
    <div class="col-12">

      <div class="privacidade">
        <h1>Política de privacidade</h1>
        <br>

        <p>
          A privacidade de suas informações é de grande importância para nós e, por esta razão, seguimos políticas rígidas e constantemente revisadas,
          visando garantir e respeitar a confidencialidade dos dados, bem como assegurar a conformidade com as normas legais e regulatórias pertinentes.
        </p>
        <p>
          Por meio desta Política, apresentaremos as diretrizes gerais relacionadas ao tratamento de dados pessoais de alunos, candidatos, colaboradores e
          visitantes do portal de notícias da YDUQS (“Companhia”), de suas controladas e marcas de ensino, demonstrando o nosso comprometimento e as medidas
          adotadas para assegurar a proteção dos dados pessoais coletados.
        </p>
        <p>
          Procedimentos técnicos e organizacionais estão em vigor para garantir que a sua informação esteja sempre segura. Como parte destes procedimentos,
          regularmente treinamos e conscientizamos nossos colaboradores sobre a importância da manutenção, salvaguarda e respeito da sua informação pessoal.
          Consideramos a violação da privacidade dos dados pessoais algo muito sério e por isso estabelecemos métodos de controle e medidas apropriadas para mitigar eventuais riscos.
        </p>
        <br><br>

        <h2>1. CONCEITOS:</h2>
        <br>
        <p>
          i.
          <span>
            ANPD: Autoridade Nacional de Proteção de Dados (“ANPD”), órgão da Administração Pública responsável por zelar, implementar e fiscalizar
            o cumprimento da Lei Geral de Proteção de Dados em todo o território nacional;
          </span>
        </p>
        <p>
          ii.
          <span>
            Comitê de Privacidade: Comitê interno e permanente da YDUQS responsável por subsidiar o DPO e, em última análise, o CEO, na tomada de
            decisões relativas às estratégias, políticas e normas relacionadas à Lei Geral de Proteção de Dados e a adequação da companhia aos seus termos;
          </span>
        </p>
        <p>
          iii.
          <span>
            Consentimento: Manifestação livre, informada e inequívoca por meio da qual o Titular concorda com o tratamento de seus dados pessoais
            para uma finalidade determinada;
          </span>
        </p>
        <p>
          iv.
          <span>
            Cookies: No âmbito do protocolo de comunicação HTTP usado na Internet, é um pequeno arquivo de computador ou pacote de dados enviados por
            um site de Internet para o navegador do usuário, quando o utilizador visita o site;
          </span>
        </p>
        <p>
          v.
          <span>
            Dados pessoais: Informação relacionada à pessoa natural identificada ou identificável;
          </span>
        </p>
        <p>
          vi.
          <span>
            Dados Pessoais Sensíveis: Dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou
            a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando
            vinculado a uma pessoa natural;
          </span>
        </p>
        <p>
          vii.
          <span>
            Encarregado ou DPO: Pessoa indicada pelo Controlador e Operador para atuar como canal de comunicação entre o Controlador, os titulares
            dos dados e a Autoridade Nacional de Proteção de Dados;
          </span>
        </p>
        <p>
          viii.
          <span>
            Escritório de Privacidade: Equipe composta por um líder e analistas de privacidade com foco no atendimento das demandas de proteção de dados,
            consultas internas e externas. Responsável pela interação com as demais áreas para mitigação de incidentes de privacidade com reporte ao comitê de privacidade.
          </span>
        </p>
        <p>
          ix.
          <span>
            Finalidade (s): Propósitos legítimos, específicos, explícitos e informados ao Titular para tratamento de seus Dados Pessoais;
          </span>
        </p>
        <p>
          x.
          <span>
            LGPD: Lei Geral de Proteção de Dados, Lei nº 13.709/2018;
          </span>
        </p>
        <p>
          xi.
          <span>
            Titular: pessoa natural a quem se referem os dados pessoais que são objeto de tratamento;
          </span>
        </p>
        <p>
          xii.
          <span>
            Uso compartilhado dos dados: Comunicação, difusão, transferência internacional, interconexão de dados pessoais ou tratamento compartilhado
            de bancos de dados pessoais por órgãos e entidades públicos no cumprimento de suas competências legais, ou entre esses e entes privados,
            reciprocamente, com autorização específica, para uma ou mais modalidades de tratamento permitidas por esses entes públicos, ou entre entes privados;
          </span>
        </p>
        <p>
          xiii.
          <span>
            YDUQS: YDUQS Participações S.A., suas controladas e marcas de ensino.
          </span>
        </p>
        <br><br>

        <h2>2. COLETA E TRATAMENTO DE DADOS:</h2>
        <br>

        <h5>2.1. Diferentes Formas de Coleta de Dados:</h5>

        <p>
          A YDUQS pode coletar dados pessoais de diferentes maneiras, dentre elas por meio do acesso e preenchimento, pelo titular, de formulários e
          questionários em e-mails, sites, correio, aplicativos, telefone, contratos ou por meio da entrega de documentos pessoais, em situações que incluem, por exemplo:
        </p>
        <div class="ms-3">
          <p>
            1. ter ou pretender ter relação de trabalho com a YDUQS;
          </p>
          <p>
            2. solicitar produtos ou serviços da YDUQS;
          </p>
          <p>
            3. criar uma conta nos nossos Sites ou Aplicativos;
          </p>
          <p>
            4. contratar serviços da YDUQS;
          </p>
          <p>
            5. solicitar o envio de materiais de marketing ou institucionais;
          </p>
          <p>
            6. participar de promoção ou pesquisa;
          </p>
          <p>
            7. nos contatar para transmitir algum feedback;
          </p>
          <p>
            8. por meio de dados de acesso aos nossos sites e aplicativos e;
          </p>
          <p>
            9. por meio de fontes publicamente disponíveis.
          </p>
          <br>
        </div>

        <p>
          As informações coletadas de usuários que se utilizam dos sites da YDUQS, por meio de Cookies, incluem, dentre outros:
        </p>
        <div class="ms-3">
          <p>
            1. o navegador de acesso do usuário;
          </p>
          <p>
            2. endereço do protocolo de Internet (IP);
          </p>
          <p>
            3. data e hora do acesso;
          </p>
          <p>
            4. a localização do usuário e;
          </p>
          <p>
            5. as ações do usuário no site.
          </p>
          <br>
        </div>
        <p>
          Os Titulares de Dados Pessoais podem estar associados a identificadores on-line fornecidos por seus dispositivos, aplicativos, ferramentas e
          protocolos, como endereços de IP e cookies. Estas informações, quando combinadas com identificadores únicos e outros dados recebidos por servidores,
          podem ser usados para identificá-los.
        </p>
        <p>
          Os dados serão tratados pela YDUQS tão somente para as Finalidades determinadas por esta política, mediante o Consentimento do Titular
          ou em decorrência das demais hipóteses legalmente previstas.
        </p>
        <p>
          A YDUQS, pautada no princípio da transparência, informa que a coleta de cookies no momento do acesso às suas aplicações terá por
          finalidade a otimização de funcionalidades e a personalização do acesso, customizando a experiência do Titular, garantindo que será
          inteiramente responsável pelos seus dados e sendo vedado qualquer tratamento em desconformidade com esta política.
        </p>
        <br>

        <h5>2.2. Motivos da coleta e utilização de dados pessoais:</h5>
        <p>
          A YDUQS poderá utilizar a sua informação pessoal para as seguintes finalidades:
        </p>
        <p>
          a. <span class="border-bottom">Envio de Conteúdo:</span>
        </p>
        <p>
          O e-mail e telefone são utilizados para envio de conteúdo requisitado por você quando do preenchimento de formulários. Também podem ser utilizados
          para envio de newsletters, para contato via WhatsApp, ligação, mensagens instantâneas e comunicação via e-mail, sempre contendo informações
          relacionadas aos assuntos inseridos no escopo geral deste website e, geralmente, relacionados aos interesses manifestados pelo usuário.
        </p>
        <p>
          Por fim, o e-mail e telefone serão utilizados, ainda, para comunicar o lançamento de novos cursos, eventos e novidades em geral sobre
          a YDUQS e suas controladas. Nossa equipe poderá entrar em contato via e-mail ou telefone para apresentar produtos e serviços.
        </p>
        <p>
          <span class="border-bottom"> b. Publicidade:</span>
        </p>
        <p>
          Mediante o seu consentimento, podemos utilizar os seus dados pessoais para enviar comunicações de marketing por e-mail, telefone, ou outras
          formas previamente consentidas (incluindo campanhas em redes sociais) para assegurar que esteja sempre ciente sobre nossos serviços e produtos.
        </p>
        <p>
          <span class="border-bottom">c. Para garantia da segurança patrimonial e de sua integridade física:</span>
        </p>
        <p>
          Caso venha visitar nossas instalações, podemos gravar a sua imagem por meio do nosso sistema de monitoramento por razões de segurança.
          Serão coletadas também informações pessoais para providenciar as suas credenciais de acesso, de forma a manter um ambiente de trabalho
          seguro. Trata-se de procedimento estabelecido para proporcionar segurança tecnológica, prevenindo contra potenciais crimes, segurança de ativos e medidas contra invasão.
        </p>
        <br>

        <h2>3. CONSENTIMENTO:</h2>
        <p>
          Caso o tratamento de dados pessoais necessite do seu Consentimento, este será livremente manifestado por você, de maneira informada e
          inequívoca e para finalidade determinada, por meio dos nossos sites e aplicativos.
        </p>
        <br>

        <h2>4. COMPARTILHAMENTO DE DADOS PESSOAIS:</h2>
        <p>
          Observados os critérios definidos nesta Política, a YDUQS não divulgará dados pessoais dos seus alunos, candidatos e visitantes a uma terceira parte, exceto para:
        </p>
        <p>
          I.
          <span>
            Cumprimento de obrigação legal ou regulatória;
          </span>
        </p>
        <p>
          II.
          <span>
            Tratamento de dados compartilhados necessários à execução, pela administração pública, de políticas públicas previstas em leis ou regulamentos;
          </span>
        </p>
        <p>
          III.
          <span>
            Realização de estudos por órgão de pesquisa, garantida sempre que possível a anonimização dos dados pessoais;
          </span>
        </p>
        <p>
          IV.
          <span>
            Execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o Titular, a pedido do Titular dos dados;
          </span>
        </p>
        <p>
          V.
          <span>
            Exercício regular de direitos, inclusive em contrato e em processo judicial, administrativo e arbitral (nos termos da Lei nº 9.307,
            de 23 de setembro de 1996 – Lei da Arbitragem);
          </span>
        </p>
        <p>
          VI.
          <span>
            Tutela da saúde, exclusivamente em procedimento realizado por profissionais da saúde, serviços de saúde ou autoridade sanitária;
          </span>
        </p>
        <p>
          VII.
          <span>
            Proteção da vida ou da incolumidade do Titular ou de terceiro;
          </span>
        </p>
        <p>
          VIII.
          <span>
            Quando necessário, para atender aos interesses legítimos do controlador ou de terceiro, exceto no caso de prevalecerem direitos e liberdades
            fundamentais do Titular que exijam a proteção dos dados pessoais;
          </span>
        </p>
        <p>
          IX.
          <span>
            Para proteção do crédito, inclusive quanto ao disposto na legislação pertinente.
          </span>
        </p>
        <br>

        <p>
          As entidades terceiras para as quais eventualmente serão compartilhadas informações pessoais nos termos do disposto acima, deverão executar
          o processamento de forma a garantir o cumprimento das obrigações legais. Ainda assim, exigimos que as organizações que manuseiam ou obtenham
          esses dados pessoais, reconheçam a criticidade desta informação, respeitem todos os direitos individuais de privacidade e cumpram com todas
          as leis de proteção de dados. As entidades terceiras poderão representar, sem a estas se limitar:
        </p>

        <div class="ms-3">
          <p>
            · Demais empresas do Grupo YDUQS, nos limites das finalidades estabelecidas nesta política;
          </p>
          <p>
            · Prestadores de serviços comerciais e consultores especializados contratados para serviços administrativos, financeiros, legais, segurança,
            pesquisa, geração e controle pagamento e outros serviços;
          </p>
          <p>
            · Entidades terceiras que estejam realizando trabalhos de Auditoria;
          </p>
          <p>
            · Qualquer pessoa ou empresa desde que tenhamos o seu Consentimento;
          </p>
          <p>
            · Na eventualidade de divulgarmos os seus dados pessoais a partes empresariais, tais como empresas de pagamentos, de forma a desempenhar os
            serviços solicitados, as referidas partes poderão armazenar a sua informação de forma a cumprir com as suas obrigações nos termos da LGPD.
          </p>
        </div>
        <p>
          Os candidatos, alunos e visitantes aceitam e consentem com a eventual análise dos dados recolhidos quando do acesso aos nossos sites e
          aplicativos ou por outros meios citados neste Política, para fins estatísticos, de forma a melhorar e aprimorar os nossos serviços.
        </p>
        <br>

        <h2>5. TRANSFERÊNCIA INTERNACIONAL DOS DADOS:</h2>
        <p>
          A YDUQS trata as suas informações pessoais em território nacional e em países que possuam legislações semelhantes e equivalentes. Além disso,
          mantém clausulas específicas para assegurar o correto tratamento, alinhadas com as leis dos países de destino e regulamentações brasileiras.
        </p>
        <br>

        <h2>6. SEGURANÇA E PRIVACIDADE DOS DADOS PESSOAIS:</h2>
        <p>
          Quaisquer informações que os usuários transfiram para a YDUQS, serão coletadas por meios éticos e legais,
          guardadas de acordo com padrões rígidos de segurança e confidencialidade, podendo ter um ou mais propósitos de
          utilização, sobre os quais os usuários serão previamente informados.
        </p>
        <br>

        <h2>7. ARMAZENAMENTO DE INFORMAÇÃO PESSOAL E PERÍODO DE RETENÇÃO:</h2>
        <p>
          A YDUQS deverá manter as suas informações pessoais pelo tempo necessário ao cumprimento da finalidade proposta ou por outros motivos legalmente permitidos.
        </p>
        <br>

        <h2> 8. PRERROGATIVAS DO TITULAR</h2>
        <p>
          A Companhia se compromete perante o candidato, aluno e visitante, salvo impedimento legal, a salvaguardar os direitos destes de acesso aos dados que
          lhe digam respeito, bem com a sua retificação, eliminação, limitação do tratamento, ao direito de se opor ao tratamento, à portabilidade dos dados
          e à retirada do Consentimento. Para tanto, o Titular deverá informar a YDUQS, por meio do canal Portal da Privacidade, a respeito de quaisquer das
          prerrogativas acima, bem como de seu eventual não interesse em receber anúncios, inclusive por e-mail (opt-out). Tratando-se de cancelamento do envio
          de comunicados e e-mails de marketing, o Titular poderá exercer o seu direito por meio da opção de cancelamento presente no rodapé dos e-mails.
        </p>
        <br>

        <h2>9. AVISO LEGAL:</h2>
        <p>
          A YDUQS não é responsável pelo uso indevido ou perda dos dados pessoais a que não tem acesso ou controle. Ficamos também isentos de responsabilidade
          diante do uso ilegal e não autorizado dessa informação como consequência de uso indevido ou desvio das suas credenciais de acesso, conduta, atos ou
          omissões negligentes ou maliciosas da sua parte ou de alguém autorizado a o representar.
        </p>
        <br>

        <a href="https://privacidade.yduqs.com.br/privacidade" target="_blank" class="text-center">Politica de privacidade</a>
        <button type="button" class="btn mt-3" (click)="aceitar()">Aceitar os Termos</button>

      </div>
    </div>

  </div>
</div>
