import { AbstractControl, ValidationErrors } from '@angular/forms';

function CpfValidator(control: AbstractControl): ValidationErrors | null {
  const cpf = String(control.value).replace(/\D+/gi, '');
  const invalid = { invalid: true };

  let numbers: any;
  let digits: string;
  let sum: number;
  let result: number;
  let equalDigits = 1;

  for (let i = 0; i < cpf.length - 1; i++) {
    if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
      equalDigits = 0;
      break;
    }
  }

  if (cpf) {
    if (!equalDigits) {
      numbers = cpf.substring(0, 9);
      digits = cpf.substring(9);
      sum = 0;

      for (let i = 10; i > 1; i--) {
        sum += numbers.charAt(10 - i) * i;
      }

      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (String(result) !== digits.charAt(0)) {
        return invalid;
      }

      numbers = cpf.substring(0, 10);
      sum = 0;
      for (let i = 11; i > 1; i--) {
        sum += numbers.charAt(11 - i) * i;
      }

      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (String(result) !== digits.charAt(1)) {
        return invalid;
      }

      return null;
    } else {
      return invalid;
    }
  } else {
    return null;
  }
}

export default CpfValidator;
