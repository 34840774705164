export class Campanha {
  constructor(
    acao: string,
    estado: string,
    marca: string,
    regional: string,
    nucleo: string,
    municipio: string,
    bairro: string,
    local: string,
    localCaptura: string
  ) {
    this.acao = acao;
    this.estado = estado;
    this.marca = marca;
    this.regional = regional;
    this.nucleo = nucleo;
    this.municipio = municipio;
    this.bairro = bairro;
    this.local = local;
    this.localCaptura = localCaptura;
  }

  acao: string;
  estado: string;
  marca: string;
  regional: string;
  nucleo: string;
  municipio: string;
  bairro: string;
  local: string;
  localCaptura: string;
}

export class CampanhaPorId {
  constructor(data: Partial<CampanhaPorId>) {
    Object.assign(this, data);
    this.termino = new Date(this.termino);
    this.inicio = new Date(this.inicio);
    if (this.canal != 'H') {
      this.marca = this.marcaCampanhaUsuario;
      this.uf = this.ufCampanhaUsuario;
      this.regional = this.regionalCampanhaUsuario;
      this.nucleoHUB = this.nucleoHUbCampanhaUsuario;
    } else {
      this.marca = this.marcaCampanha;
      this.uf = this.ufCampanha;
      this.regional = this.regionalCampanha;
      this.nucleoHUB = this.nucleoHUbCampanha;
    }
  }
  bairro: string;
  canal: string;
  canalSalesforce: string;
  idSalesforce: string;
  cidade: string | null;
  campus: string | null;
  descricaoCampanha: string;  
  escola: string | null;
  idCampanha: number;
  inicio: Date; // You can use Date type here if needed
  leadsNaoEnviados: number;
  leadsRegistrados: number;
  marca: string;
  marcaCampanha: string;
  marcaCampanhaUsuario: string;
  nucleoHUB: string;
  nucleoHUbCampanha: string | null;
  nucleoHUbCampanhaUsuario: string;
  cidadeCampanhaUsuario: string;
  cidadeCampanha: string;
  regional: string;
  regionalCampanha: string | null;
  regionalCampanhaUsuario: string;
  termino: Date; // You can use Date type here if needed
  uf: string;
  ufCampanha: string;
  ufCampanhaUsuario: string;
  idCampanhaUsuario: number;
  bairroEscola: string;
}
