import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-privacidade',
  templateUrl: './privacidade.component.html',
  styleUrls: ['./privacidade.component.scss'],
})
export class PrivacidadeComponent {
  @Output() aceitarPrivacidade = new EventEmitter();

  aceitar() {
    this.aceitarPrivacidade.emit(true);
  }
}
