import { Injectable } from '@angular/core';
import { Campanha } from '@models/Campanha';
import { of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CampanhaService {
  dadosCampanha: Campanha;
  dadosCampanhaTela: Campanha;

  postCampanha(campanha) {
    localStorage.setItem('campanha', JSON.stringify(campanha));
    this.dadosCampanha = campanha;
  }

  getCampanha(): Observable<Campanha> {
    return of(JSON.parse(localStorage.getItem('campanha')));
  }

  postCampanhaTela(campanha) {
    localStorage.setItem('campanhaTela', JSON.stringify(campanha));
    this.dadosCampanhaTela = campanha;
  }

  getCampanhaTela(): Observable<Campanha> {
    return of(JSON.parse(localStorage.getItem('campanhaTela')));
  }

  limparCampanha() {
    localStorage.removeItem('contadorCadastroTotal');
  }
}
