import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();

    if (token && token !== null && req.url.startsWith(environment.baseUrl)) {
      const cloneReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`),
      });

      return next.handle(cloneReq).pipe(
        tap(
          () => {
            return true;
          },
          error => {
            if (error.status === 401) {
              this.authService.logout();
              this.router.navigate(['login']);
            } else if (error.status === 500) {
              this.toastr.error('Problemas para processar a requisição.');
            }
          }
        )
      );
    } else {
      return next.handle(req.clone());
    }
  }
}
