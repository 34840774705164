import { AbstractControl, ValidationErrors } from '@angular/forms';

function EmailValidator(control: AbstractControl): ValidationErrors | null {
  const email = String(control.value);
  const invalid = { invalid: true };

  if (email) {
    if (
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return null;
    } else {
      return invalid;
    }
  } else {
    return null;
  }
}

export default EmailValidator;
