import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
// Services
import { AuthService } from '@services/auth.service';
import { CampanhaService } from '@services/campanha.service';
import { GeraisService } from '@services/gerais.service';
import { LeadService } from '@services/lead.service';
import { ToastrService } from 'ngx-toastr';

// Model
import { SKU, Curso, DadosGerais, Escolaridade, Metodologia } from '@models/Gerais';
import CpfValidator from '@services/cpf.validator';
import EmailValidator from '@services/email.validator';
import { Campanha } from '@models/Campanha';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-campanha',
  templateUrl: './form-campanha.page.html',
  styleUrls: ['./form-campanha.page.scss'],
})
export class FormCampanhaPage implements OnInit {
  appVersion = environment.version;

  isLoading = false;
  salvando = false;

  formCampanha!: FormGroup;
  formLead!: FormGroup;

  exibeConfirmacao = false;
  mensagem = '';
  dialogoCallBack = () => {};

  // Campanha atual
  dadosCampanha: Campanha;
  dadosCampanhaTela: Campanha;
  leads = {
    total: 0,
    enviado: 0, // = total - não enviado
    naoEnviado: 0,
  };

  // Campanha
  cursos: SKU[];
  tiposCursos: string[] = [];
  campus: SKU[];

  // Leads
  escolaridades: Escolaridade[];
  metodologias: Metodologia[];
  dadosGerais: DadosGerais;
  municipios: string[];
  bairros: string[];
  locaisCaptura: string[];
  canal: string;
  idCampanha: number;

  indicadorEAD = false;
  dadosGeraisCarregado = false;

  qrCode: string = null;

  // etapa
  registrarLead = true;

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private authService: AuthService,
    private leadService: LeadService,
    private geraisService: GeraisService,
    private campanhaService: CampanhaService
  ) {}

  ngOnInit(): void {
    // Inicializa formulários
    this.initFormCampanha();
    this.initFormLead();

    this.cadastrarLeads();

    // Carrega Dados Gerais
    this.geraisService.inicializar().then(
      () => {
        this.dadosGeraisCarregado = false;
        this.geraisService
          .getDadosGeraisOffline()
          .then(dadosGerais => {
            this.dadosGerais = dadosGerais;
            console.log(this.dadosGerais);
            this.cadastrarLeads();

            this.selectCurso();
            this.selectMetodologia();
            this.selectBairro();
            this.selectAcao();

            console.log(dadosGerais);
          })
          .catch(error => {
            // Handle any errors that might occur during the execution
            console.error(error);
          });
      },
      err => this.toastrService.error(err)
    );

    // Dados da campanha para envio à api e preenchimento do formulário
    if ('funcao' in localStorage) {
      if (localStorage.getItem('funcao') === '4') {
        this.indicadorEAD = true;
      }
    }

    // Dados da campanha para envio à api e preenchimento do formulário
    if ('campanha' in localStorage) {
      this.campanhaService.getCampanha().subscribe(response => {
        this.dadosCampanha = response;
        this.formCampanha.setValue(this.dadosCampanha);
        console.log(this.dadosCampanha);
      });
    }

    // Dados da campanha para exibição na tela
    if ('campanhaTela' in localStorage) {
      this.campanhaService.getCampanhaTela().subscribe(response => {
        this.dadosCampanhaTela = response;
        console.log(this.dadosCampanhaTela);
      });
    }

    // Contadores
    const contadores = this.leadService.getContadores();
    contadores.total.subscribe(obs => {
      this.leads.total = obs;
      this.leads.enviado = this.leads.total - this.leads.naoEnviado;
    });
    contadores.aguardando.subscribe(obs => {
      this.leads.naoEnviado = obs;
      this.leads.enviado = this.leads.total - this.leads.naoEnviado;
    });
    this.leadService.atualizarContadores();

    //QR Code
    this.generateQRCode();
  }

  // Formulário Campanha
  initFormCampanha() {
    this.formCampanha = new FormGroup({
      acao: new FormControl('', [Validators.required]),
      estado: new FormControl('', [Validators.required]),
      marca: new FormControl('', [Validators.required]),
      regional: new FormControl('', [Validators.required]),
      municipio: new FormControl('', [Validators.required]),
      bairro: new FormControl(''),
      local: new FormControl('', [Validators.required]),
    });

    if (this.dadosCampanha) {
      this.formCampanha.reset(this.dadosCampanha);
    }
  }

  // Formulário Lead
  initFormLead() {
    this.formLead = new FormGroup({
      nomeLead: new FormControl('', [Validators.required]),
      emailLead: new FormControl('', [Validators.required, EmailValidator]),
      telefoneLead: new FormControl('', [Validators.required]),
      cpfLead: new FormControl('', [CpfValidator]),
      empresa: new FormControl(''),
      escolaridade: new FormControl(''),
      tipoCurso: new FormControl(''),
      curso: new FormControl('', [Validators.required]),
      unidadeInteresse: new FormControl(''),
      metodologia: new FormControl(''),
      campus: new FormControl(''),
      anoConclusaoEnsinoMedio: new FormControl(0),
    });
  }

  cadastrarLeads() {
    //this.editarCampanha = false;
    //this.registrarLead = true;
    //this.dadosGerais.cursos.forEach(x => {
    //  if (!this.tiposCursos.includes(x.tipoCurso)) {
    //    this.tiposCursos.push(x.tipoCurso);
    //    console.log(this.tiposCursos);
    //  }
    //});

    // Dados gerais - Lead
    this.dadosIniciais('lead');

    this.formLead.reset();
    this.formLead.controls['escolaridade'].setValue(null);
    this.formLead.controls['metodologia'].setValue(null);

    /*   setTimeout(() => {
        document
          .getElementById('Formulario')
          .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 250); */
  }

  etapa(action: string) {
    if ('voltar') {
    }
  }

  dadosIniciais(tipo) {
    if (tipo === 'campanha') {
      // Para uso no formulário de Campanha
      // Informações não dependentes carregadas aqui
      //this.geraisService.getEventos().subscribe(response => {
      //  this.eventos = this.dadosCampanha?.evento && this.eventos ? this.eventos : response;
      //});
      //this.geraisService.getFuncoes().subscribe(response => {
      //  this.funcoes = response;
      //});
      // Preecher ba tela se campanha existente, fazendo a seleção dependente
      //if (this.dadosCampanha) {
      //  if (this.dadosCampanha.acao) {
      //    this.selectEvento();
      //    if (this.dadosCampanha.estado) {
      //      this.selectEstado();
      //      if (this.dadosCampanha.marca) {
      //        this.selectMarca(this.dadosCampanha.marca);
      //        if (this.dadosCampanha.regional) {
      //          this.selectRegional(this.dadosCampanha.regional);
      //          if (this.dadosCampanha.nucleo) {
      //            this.selectNucleo();
      //            if (this.dadosCampanha.municipio) {
      //              this.selectMunicipio(this.dadosCampanha.municipio);
      //              if (this.dadosCampanha.bairro) {
      //                this.selectBairro(this.dadosCampanha.bairro);
      //              }
      //            }
      //          }
      //        }
      //      }
      //    }
      //  }
      //}
    } else {
      // Para uso no formulário de Leads
      //if (this.canal == 'E') {
      //  this.cursos = this.dadosGerais.cursos.filter(x => {
      //    return x.tipoCurso == 'GRADUAÇÃO';
      //  });
      //} else {
      //  this.cursos = this.dadosGerais.cursos;
      //}
      // Para uso no formulário de Leads
      this.cursos = this.dadosGerais.skus;
      this.campus = this.dadosGerais.skus;
    }
  }

  salvarLead() {
    this.salvando = true;

    // Recupera os novos dados de campanha
    this.campanhaService.getCampanha().subscribe(response => {
      this.dadosCampanha = response;

      // Informações de envio
      const dados = {
        // Obrigatórios
        ...this.formLead.value,
        marca: this.dadosCampanha.marca,
        regional: this.dadosCampanha.regional,
        nucleoHub: this.dadosCampanha.nucleo,
        UF: this.dadosCampanha.estado,
        cidade: this.dadosCampanha.municipio,
        bairro: this.dadosCampanha.bairro == '' ? null : this.dadosCampanha.bairro,
        localCapturaEscola: this.dadosCampanha.local,
        idUsuario: 'idUsuario' in localStorage ? parseInt(localStorage.getItem('idUsuario')) : 1,
        idMetodologia: this.dadosGerais.metodologias.filter(x => {
          return x.nomeMetodologia == this.formLead.controls['metodologia'].value;
        })[0].chave,
        idEscolaridade: this.dadosGerais.escolaridades.filter(x => {
          return x.nomeEscolaridade == this.formLead.controls['escolaridade'].value;
        })[0].chave,
        idCampanha: this.idCampanha,
      };

      // Gravação do lead
      this.leadService.postLead(dados).then(
        response => {
          this.toastrService.success('Lead salvo com sucesso!');
          this.salvando = false;

          this.etapa('reset');
          this.formLead.reset();
          this.formLead.controls['escolaridade'].setValue(null);
          this.formLead.controls['metodologia'].setValue(null);
          console.log(response);
        },
        error => {
          this.toastrService.error(error.message);
          console.log(error);
        }
      );
    });
  }

  // ---------------------------------
  selectAcao() {
    const acao = this.dadosGerais.campanhas.filter(x => {
      return x.descricaoCampanha == this.formCampanha.controls['acao'].value;
    })[0];
    setTimeout(() => {
      this.canal = acao.canal;
      this.idCampanha = acao.idCampanha;
      this.formCampanha.controls['estado'].setValue(acao.ufCampanhaUsuario);
      this.formCampanha.controls['marca'].setValue(acao.marcaCampanhaUsuario);
      this.formCampanha.controls['regional'].setValue(acao.regionalCampanhaUsuario);
      this.formCampanha.controls['nucleo'].setValue(acao.nucleoHUbCampanhaUsuario);
      if (acao.cidade) {
        this.municipios = [];
        this.municipios.push(acao.cidade);
        this.formCampanha.controls['municipio'].setValue(acao.cidade);
      } else {
        //Recupera uma lista de municipios dos dados gerais, sem repetição.
        const municipiosUnicos = new Set<string>(); //Esse set é uma lista que não aceita elementos repetidos
        this.dadosGerais.locaisCampanha.forEach(obj => {
          municipiosUnicos.add(obj.cidade);
        });
        this.municipios = Array.from(municipiosUnicos);
        if (this.municipios.length == 1) {
          this.formCampanha.controls['municipio'].setValue(this.municipios[0]);
        }
      }
      //Organiza os bairros sem repetição
      if (this.canal != 'H') {
        const bairrosUnicos = new Set<string>(); //Esse set é uma lista que não aceita elementos repetidos
        this.dadosGerais.locaisCampanha.forEach(obj => {
          bairrosUnicos.add(obj.bairro);
        });
        this.bairros = Array.from(bairrosUnicos);
        if (this.bairros.length == 1) {
          this.formCampanha.controls['bairro'].setValue(this.bairros[0]);
        }
        //Organiza os locais de captura sem repetição
        const locaisCapturaUnicos = new Set<string>(); //Esse set é uma lista que não aceita elementos repetidos

        this.dadosGerais.locaisCampanha.forEach(obj => {
          locaisCapturaUnicos.add(obj.localCaptura);
        });
        this.locaisCaptura = Array.from(locaisCapturaUnicos);
        if (this.locaisCaptura.length == 1) {
          this.formCampanha.controls['local'].setValue(this.locaisCaptura[0]);
        }
      } else {
        this.locaisCaptura = [];
        this.locaisCaptura.push(acao.escola);
        this.formCampanha.controls['local'].setValue(acao.escola);
      }
    }, 500);
  }

  selectBairro() {
    const locaisCapturaUnicos = new Set<string>(); //Esse set é uma lista que não aceita elementos repetidos
    const locais = this.dadosGerais.locaisCampanha.filter(x => {
      return x.bairro == this.formCampanha.controls['bairro'].value;
    });
    locais.forEach(obj => {
      locaisCapturaUnicos.add(obj.localCaptura);
    });
    this.locaisCaptura = Array.from(locaisCapturaUnicos);
    if (this.locaisCaptura.length == 1) {
      this.formCampanha.controls['local'].setValue(this.locaisCaptura[0]);
    }
  }

  selectMetodologia() {
    this.formLead.controls['campus'].setValue(null);
    if (this.canal === 'T') {
      this.campus = this.formLead.controls['metodologia'].value === 'Presencial'
        ? this.dadosGerais.skus.filter(x => x.modalidade === 'Presencial')
        : this.dadosGerais.skus; // Retorna todos os itens
    }
  }

  selectCurso() {
      this.dadosGerais.skus.filter(x => {
        return x.nomeCurso == this.formLead.controls['curso'].value;
      })    
  }

  // Modal de confirmação
  exibirConfirmacao(tipo) {
    this.exibeConfirmacao = true;

    if (tipo === 'editarCampanha') {
      this.isLoading = true;

      this.mensagem = 'Confirma a alteração da campanha?';

      this.dialogoCallBack = () => {
        this.salvarCampanha(this.formCampanha.value);
      };
    } else if (tipo === 'registrarLead') {
      this.isLoading = true;

      this.mensagem = 'Confirma a inclusão do lead?';

      this.dialogoCallBack = () => {
        this.salvarLead();
      };
    } else if (tipo === 'atualizarDados') {
      this.mensagem = 'Confirma a atualização da base de dados? O formulário abaixo será limpo!';

      this.dialogoCallBack = () => {
        this.sincronizarDadosGerais();
      };
    } else if (tipo === 'logOut') {
      this.mensagem =
        'Atenção, ao se desconectar, você perderá a possibilidade de criação de Leads caso esteja sem conexão com a internet!';

      this.dialogoCallBack = () => {
        this.authService.logout();
        this.router.navigate(['login']);
      };
    } else {
      // outra confirmação
    }
  }

  // Faz a atualização dos Dados Gerais manualmente
  sincronizarDadosGerais() {
    this.exibeConfirmacao = false;

    this.geraisService.inicializar(true).then(
      res => {
        if (res) {
          //this.dadosGeraisCarregado = false;
          this.campanhaService.limparCampanha(); // Limpar a campanha gravado no local storage
          this.dadosCampanha = null;
          this.dadosCampanhaTela = null;
          this.formCampanha.reset();
          this.dadosIniciais('campanha');
          this.toastrService.success('Atualização concluída!');
        }
      },
      err => {
        this.toastrService.error(err);
      }
    );
  }

  salvarCampanha(campanha) {
    this.salvando = true;
    if (this.canal == 'H') {
      campanha.bairro = '';
    }
    // Armazena as informações para envio à api
    this.campanhaService.postCampanha(campanha);

    // Recupera os novos dados de campanha
    this.dadosCampanha = this.campanhaService.dadosCampanha;

    // Dados à serem salvos
    //const dados = {
    //  evento: this.eventos.filter(i => i.idEvento === parseInt(campanha.evento))[0],
    //  estado: this.estados.filter(i => i.siglaEstado === campanha.estado)[0],
    //  marca: this.marcas.filter(i => i.idMarca === parseInt(campanha.marca))[0],
    //  regional: this.regionais.filter(i => i.idRegional === parseInt(campanha.regional))[0],
    //  nucleo: this.nucleos.filter(i => i.idNucleoHub === parseInt(campanha.nucleo))[0],
    //  municipio: this.municipios.filter(i => i.idMunicipio === parseInt(campanha.municipio))[0],
    //  bairro: this.bairros.filter(i => i.idBairro === parseInt(campanha.bairro))[0],
    //  local: this.locaisCaptura.filter(i => i.idLocalCaptura === parseInt(campanha.local))[0],
    //};

    // Armazena as informações para apresentação em tela
    /*this.campanhaService.postCampanhaTela(dados);*/

    // Recupera os novos dados de campanha
    this.dadosCampanhaTela = this.campanhaService.dadosCampanhaTela;

    // Retorna à tela principal
    this.salvando = false;
    this.etapa('reset');
  }

  generateQRCode() {
    // https://cordobo.com/2444-angular4-qrcode-generator-component/
    this.qrCode = 'https://www.vh.com.br/';
  }
}
