import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
import { LoginRequest } from '@models/LoginRequest';
import { TokenContent } from '@models/TokenContent';
import { TokenResultado } from '@models/TokenResultado';
import { environment } from '@environments/environment';
import { Usuario } from '@models/Usuario';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseURL = environment.baseUrl;
  $logado = new Subject<string>();

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  async login({ email, cpf }: any) {
    const request: any = {
      email: email,
      cpf: cpf,
    };

    try {
      const response = await this.http
        .post<TokenResultado>(`${this.baseURL}Login`, request)
        .toPromise();
      const decodedJWT = this.decodePayloadJWT(response.token);
      this.setToken(response.token, decodedJWT.idUsuario, decodedJWT.codigoPDV);
      this.$logado.next(response.token);
    } catch (Exception) {
      if (Exception.status === 401) {
        this.toastr.error(Exception.error);
      } else {
        this.toastr.error('Não foi possível conectar no momento. Tente mais tarde!');
      }
      console.log('Erro: ', Exception);
    }
  }

  private decodePayloadJWT(payload: string): TokenContent {
    try {
      return jwt_decode<TokenContent>(payload);
    } catch (Error) {
      return null;
    }
  }

  setToken(token: string, idUsuario, codigoPDV): void {
    localStorage.setItem('token', token);
    localStorage.setItem('idUsuario', idUsuario);
    localStorage.setItem('codigoPDV', codigoPDV);
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  getIdusuario(): string | null {
    return localStorage.getItem('idUsuario');
  }

  getCodigoPDV(): string | null {
    return localStorage.getItem('codigoPDV');
  }

  getNomeUsuario() {
    return this.decodePayloadJWT(localStorage.getItem('token')).nomeUsuario;
  }

  logout(): void {
    //localStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('idUsuario');
    localStorage.removeItem('codigoPDV');
    localStorage.removeItem('campanha');
  }

  // Criação de usuário
  postUsuario(loginRequest: LoginRequest) {
    return this.http.post<LoginRequest>(`${this.baseURL}Criar_Usuario`, loginRequest);
  }

  // Confirmação do usuário
  postEmail(usuario: Usuario) {
    return this.http.post<Usuario>(`${this.baseURL}Confirmar_Email`, usuario);
  }
}
