import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@services/auth.service';
import CpfValidator from '@services/cpf.validator';
import EmailValidator from '@services/email.validator';
import { GeraisService } from '@services/gerais.service';
import { Lead } from '@models/Lead';
import { CSVService } from '@services/csv.service';
import { environment } from 'src/environments/environment';
import { EaseOutAnimation, loaderYduqs } from 'src/app/services/animation';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [EaseOutAnimation, loaderYduqs],
})
export class LoginComponent implements OnInit {
  appVersion = environment.version;

  usuarioCadastrado = false;

  voltar = false;

  codigoValidacao: string;
  dadosLogin: any;

  privacidade = false;
  aceitoPrivacidade = false;

  captcha: string;
  captchaInput;
  backDoor = false;
  leads: Lead[] = [];

  funcoes = [
    { idFuncao: 1, funcao: 'FDV', indicadorEAD: false },
    { idFuncao: 2, funcao: 'SM', indicadorEAD: false },
    //{ "idFuncao": 3, "funcao": "Promotor", "indicadorEAD": false },
    //{ "idFuncao": 4, "funcao": "Polo", "indicadorEAD": true },
    { idFuncao: 5, funcao: 'Unidade', indicadorEAD: false },
    { idFuncao: 6, funcao: 'High School', indicadorEAD: false },
    { idFuncao: 7, funcao: 'Representante', indicadorEAD: false },
  ];

  loginForm: FormGroup;
  cadastroForm: FormGroup;

  loadingYduqs = true;
  isLoading = false;
  animation = true;

  // Para confirmação de cadastro
  novaSenhaForm: FormGroup;
  novaSenha = false;
  chaveAcesso: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    private geraisService: GeraisService,
    private csvService: CSVService
  ) {}

  ngOnInit(): void {
    // Captcha
    this.generateCaptcha();

    setTimeout(() => {
      this.loadingYduqs = false;
    }, 2600);
  }

  // Exibe modal LGPD
  lerTermos() {
    this.privacidade = true;
  }

  // Aceite do termo pelo modal
  aceitarPrivacidade(e) {
    this.cadastroForm.controls['chkLGPD'].setValue(e);
    this.privacidade = false;
  }

  // Form de login
  initLoginForm() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, EmailValidator]),
      cpf: new FormControl('', [Validators.required, CpfValidator]),
      captcha: new FormControl('', [Validators.required]),
    });

    // DEBUG!! Auto preenche campos de login
    //this.loginForm.setValue({
    //  email: 'teste@vh.com.br',
    //  cpf: '00000000191',
    //  captcha: this.captcha
    //});
  }

  // Form de cadastro
  initCadastroForm() {
    this.cadastroForm = new FormGroup({
      nome: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, EmailValidator]),
      telefone: new FormControl('', [Validators.required]),
      cpf: new FormControl('', [Validators.required, CpfValidator]),
      idFuncao: new FormControl('', [Validators.required]),
      chkLGPD: new FormControl('', [Validators.required]),
      captcha: new FormControl('', [Validators.required]),
    });

    // DEBUG!! Auto preenche campos de cadastro
    //this.cadastroForm.setValue({
    //  nome: 'Teste VH 2',
    //  email: 'email@mail.com',
    //  telefone: '2135910700',
    //  cpf: '00000000272',
    //  idFuncao: 7,
    //  chkLGPD: true,
    //  captcha: this.captcha
    //});
  }

  // Botão de login
  async onLogin() {
    this.isLoading = true;
    this.backDoor = false;

    if (
      this.loginForm.controls['email'].value == 'backdoorvh@vh.br' &&
      this.loginForm.controls['cpf'].value == '00000000191'
    ) {
      this.backDoor = true;

      this.geraisService.getLeads().subscribe(response => {
        this.leads = response;
        console.log(this.leads);
      });

      this.isLoading = false;
    } else {
      if (this.loginForm.valid) {
        await this.authService.login(this.loginForm.value);

        if (this.authService.isLoggedIn()) {
          this.router.navigate(['']);
        } else {
          this.isLoading = false;
        }
      }
    }
  }

  // Etapas
  etapa(etapa: string) {
    if (etapa === 'usuarioCadastrado') {
      this.usuarioCadastrado = true;
      // Inicializa formulário de login
      this.initLoginForm();
    } else {
      this.usuarioCadastrado = false;
    }
  }

  // Captcha
  generateCaptcha() {
    const a = 'ABCDEFGHJKMNPQRSTUVWXYZ';
    const n = '23456789';
    let cap_size = 5; // Captcha length
    let captchaTemp = '';

    function randomizer(x: number) {
      return Math.floor(Math.random() * x);
    }

    while (cap_size--) {
      // if 0 add a random alphabetic character
      if (randomizer(2) === 0) {
        captchaTemp += a[randomizer(a.length)];
      } else {
        // if 1 add a random numeric character
        captchaTemp += n[randomizer(n.length)];
      }
    }

    this.captcha = captchaTemp;
  }

  // Força uppercase no captcha
  toUpper(form, event) {
    if (form === 'loginForm') {
      this.loginForm.controls['captcha'].setValue(event.target.value.toUpperCase());
    } else {
      this.cadastroForm.controls['captcha'].setValue(event.target.value.toUpperCase());
    }
  }

  baixarCSV() {
    this.csvService.downloadCSV(this.leads, 'leads');
  }
}
