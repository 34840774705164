import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { environment } from '@environments/environment';
import {
  CampanhaGerais,
  SKU,
  DadosGerais,
  Escolaridade,
  LocalCampanha,
  Metodologia,
} from '@models/Gerais';
import { db } from './db';
import { Base, BaseList } from '@models/Base';
import { Lead } from '@models/Lead';
import { Campanha } from '../models/Campanha';
import { CampusLead, CursoLead, EscolaridadeLead, MetodologiaLead, TurnoLead } from '../models/dadosLead';

@Injectable({
  providedIn: 'root',
})
export class GeraisService {
  baseURL = `${environment.baseUrl}`;

  inicializado = false;
  novosDadosDisponiveis = false;

  constructor(private http: HttpClient) {}

  //getEventos(): Observable<Eventos[]> {
  //  return from(db.eventos.toArray());
  //}

  //getEstados(): Observable<Estados[]> {
  //  return from(db.estados.toArray());
  //}

  //getMarcas(): Observable<Marcas[]> {
  //  return from(db.marcas.toArray());
  //}

  async getDadosGeraisOffline() {
    const retorno = new DadosGerais();
    try {
      retorno.campanhas = await this.getCampanhas().toPromise();
      retorno.metodologias = await this.getMetodologias().toPromise();
      retorno.escolaridades = await this.getEscolaridades().toPromise();
      retorno.skus = await this.getSKUs().toPromise();
      retorno.locaisCampanha = await this.getLocaisCampanha().toPromise();
    } catch (error) {
      console.log(error);
    }
    return retorno;
  }

  getCampanhas(): Observable<CampanhaGerais[]> {
    return from(db.campanhas.toArray());
  }

  getSKUs(): Observable<SKU[]> {
    return from(db.skus.toArray());
  }
  
  getLeads(): Observable<Lead[]> {
    return from(db.leads.toArray());
  }

  //getRegionais(idMarca: number, indicadorEAD: boolean, siglaUf: string): Observable<Regionais[]> {
  //  if (siglaUf) {
  //    return from(
  //      db.regionais
  //        .where('estados')
  //        .equals(siglaUf)
  //        .and(item => item.idMarca == idMarca)
  //        .and(i => i.indicadorEAD == indicadorEAD)
  //        .toArray()
  //    );
  //  } else {
  //    return from(
  //      db.regionais
  //        .where('idMarca')
  //        .equals(idMarca)
  //        .and(i => i.indicadorEAD == indicadorEAD)
  //        .toArray()
  //    );
  //  }
  //}

  //getNucleosHubs(
  //  idRegional: number,
  //  indicadorEAD: boolean,
  //  siglaEstado: string
  //): Observable<NucleosHubs[]> {
  //  return from(
  //    db.nucleosHubs
  //      .where('idRegional')
  //      .equals(idRegional)
  //      .and(s => s.indicadorEAD === indicadorEAD)
  //      .and(x => x.siglaEstado === siglaEstado)
  //      .toArray()
  //  );
  //}

  //getMunicipios(siglaEstado: string): Observable<Municipios[]> {
  //  return from(db.municipios.where('siglaEstado').equals(siglaEstado).toArray());
  //}

  //getBairros(idMunicipio: number): Observable<Bairros[]> {
  //  return from(db.bairros.where('idMunicipio').equals(idMunicipio).toArray());
  //}

  //getLocaisCaptura(idBairro: number): Observable<LocaisCaptura[]> {
  //  return from(db.locaisCaptura.where('idBairro').equals(idBairro).toArray());
  //}

  //getFuncoes(): Observable<Funcoes[]> {
  //  return from(db.funcoes.toArray());
  //}

  getEscolaridades(): Observable<Escolaridade[]> {
    return from(db.escolaridades.toArray());
  }

  getLocaisCampanha(): Observable<LocalCampanha[]> {
    return from(db.locaisCampanha.toArray());
  }

  getMetodologias(): Observable<Metodologia[]> {
    return from(db.metodologias.toArray());
  }

  inicializar(forcarRefresh = false): Promise<boolean> {
    const thisComponent = this;

    return new Promise((resolve, reject) => {
      db.on('ready', () => {
        return db.campanhas.count(async count => {
          // Exibe loader na primeira chamada ao serviço
          document.body.classList.add('loader');

          if (count > 0 && !forcarRefresh) {
            console.log('Banco já foi populado');
            resolve(true);

            // Remove loader
            document.body.classList.remove('loader');
          } else {
            console.log('Database is empty. Populating from ajax call...');

            try {
              const dados = await thisComponent.getTodos();

              console.log("Got ajax response. We'll now add the objects.");
              console.log('Calling bulkAdd() to insert objects...');

              if (dados.sucesso) {
                if (!dados.resultado) {
                  reject(`Não existem campanhas habilitadas`);
                }
                await thisComponent.loadDados(dados.resultado);
                console.log('Done populating.');
                resolve(true);

                // Remove loader
                document.body.classList.remove('loader');
              } else {
                console.error(`Problemas para carregar os dados: ${dados.mensagem}`);
                reject(dados?.mensagem ?? 'Não foi possível carregar os dados gerais!');

                // Remove loader
                document.body.classList.remove('loader');
              }
            } catch (err) {
              console.error(`Problemas para carregar os dados: ${err.message}`);
              //reject(`Problemas para carregar os dados: ${err.message}`);
              reject(
                `Não foi possível carregar as informações iniciais. Por favor, certifique-se que esteja conectado à internet.`
              );

              // Remove loader
              document.body.classList.remove('loader');
            }
          }
        });
      });

      db.open();
    });
  }

  // Lista tudo
  getTodos(): Promise<Base<DadosGerais>> {
    return this.http.get<Base<DadosGerais>>(`${this.baseURL}Dados_Gerais`).toPromise(); // .pipe(first()).subscribe((dados) => this.loadDados(dados));
  }

  async loadDados(dados: DadosGerais) {
    // Limpa os velhos
    await db.campanhas.clear();
    await db.escolaridades.clear();
    await db.metodologias.clear();
    await db.skus.clear();
    await db.locaisCampanha.clear();

    // Insere os novos
    await db.campanhas.bulkPut(dados.campanhas);
    await db.escolaridades.bulkPut(dados.escolaridades);
    await db.skus.bulkPut(dados.skus);
    await db.metodologias.bulkPut(dados.metodologias);
    await db.locaisCampanha.bulkPut(dados.locaisCampanha);
  }


  getEscolaridadesBanco() {
    return this.http.get<BaseList<EscolaridadeLead>>(`${this.baseURL}escolaridade_listar`);
  }

  getCursosBanco() {
    return this.http.get<BaseList<CursoLead>>(`${this.baseURL}curso_listar`);
  }

  getMetodologiasBanco(nomeCurso: string) {
    return this.http.get<BaseList<MetodologiaLead>>(`${this.baseURL}metodologia_listar?nomeCurso=${nomeCurso}`);
  }

  getTurnoBanco(nomeCurso: string, modalidade: string) {
    return this.http.get<BaseList<TurnoLead>>(`${this.baseURL}turno_listar?nomeCurso=${nomeCurso}&modalidade=${modalidade}`);
  }

  getCampusBanco(nomeCurso: string, nomeModalidade: string, nomeTurno: string) {
    return this.http.get<BaseList<CampusLead>>(
      `${this.baseURL}campus_listar?nomeCurso=${nomeCurso}&nomeModalidade=${nomeModalidade}&nomeTurno=${nomeTurno}`
    );
  }
}
