<div class="container-fluid">

  <div class="navbar">
    <div class="marca">

      <ng-container *ngIf="dadosUrl.marca === 'ESTÁCIO' || dadosUrl.marca === 'ESTACIO'">
        <img src="/assets/images/estacio.svg" alt="ESTÁCIO" style="width: 110px">
      </ng-container>

      <ng-container *ngIf="dadosUrl.marca === 'WYDEN'">
        <img src="/assets/images/wyden.png" alt="WYDEN" style="width: 110px">
      </ng-container>

      <ng-container *ngIf="dadosUrl.marca === 'IBMEC'">
        <img src="/assets/images/ibmec.png" alt="IBMEC" style="width: 110px">
      </ng-container>

      <ng-container *ngIf="dadosUrl.marca === 'IDOMED'">
        <img src="/assets/images/idomed.png" alt="IDOMED" style="width: 110px">
      </ng-container>

      <ng-container *ngIf="dadosUrl.marca === 'DAMÁSIO'">
        <img src="/assets/images/damasio.png" alt="DAMÁSIO" style="width: 110px">
      </ng-container>

    </div>

    <div class="nome-campanha">
      <h1>{{dadosUrl.descricaoCampanha}}</h1>
    </div>
  </div>

  <form [formGroup]="formLead" (ngSubmit)="salvarLead()">
    <h1 class="title-form">Insira seus dados</h1>
    <div class="row">
      <div class="col-12">

        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Nome" formControlName="nomeLead">
          <label>Nome *</label>
        </div>

        <div class="form-floating">
          <input type="email" class="form-control" placeholder="E-mail" formControlName="emailLead">
          <label>E-mail *</label>
        </div>

        <div class="form-floating">
          <input type="text" class="form-control" mask="(00) 00000-0000||(00) 0000-0000"
                 placeholder="Telefone" formControlName="telefoneLead">
          <label>Telefone *</label>
        </div>

        <div class="form-floating">
          <input type="tel" class="form-control" mask="000.000.000-00" placeholder="CPF"
                 formControlName="cpfLead">
          <label>CPF </label>
        </div>

        <div class="form-floating" *ngIf="dadosUrl?.canal === 'T'">
          <input type="text" class="form-control" placeholder="Empresa" formControlName="empresa">
          <label>Empresa </label>
        </div>

        <div class="form-floating">
          <select class="form-select" formControlName="escolaridade">
            <option selected="selected" [ngValue]="null">Selecione a escolaridade</option>
            <option *ngFor="let item of escolaridades" [ngValue]="item.nomeEscolaridade">
              {{
                  item.nomeEscolaridade
              }}
            </option>

          </select>
          <label>Escolaridade </label>
        </div>

        <!--<div class="form-floating" *ngIf="dadosUrl.canal !== 'E' && dadosUrl.canal !== 'H'">
          <select class="form-select" formControlName="tipoCurso" (change)="selectTipoCurso()">
            <option selected="selected" value="null">Selecione o tipo de curso</option>
            <option value="GRADUAÇÃO">GRADUAÇÃO</option>
            <option value="PÓS GRADUAÇÃO">PÓS GRADUAÇÃO</option>
          </select>
          <label>Tipo de curso *</label>
        </div>-->

        <div class="form-floating">
          <select class="form-select" formControlName="curso" (change)="selectCurso()">
            <option selected="selected" [ngValue]="null">Selecione o curso</option>
            <option *ngFor="let item of cursos" [ngValue]="item.nomeCurso">{{ item.nomeCurso }}</option>
          </select>
          <label>Curso de interesse *</label>
        </div>

        <!--<div class="form-floating">
            <input type="text" class="form-control" placeholder="Unidade de interesse" formControlName="unidadeInteresse">
            <label>Unidade de interesse</label>
        </div>-->

        <div class="form-floating" *ngIf="dadosUrl?.canal !== 'H'">
          <select class="form-select" formControlName="metodologia" (change)="selectMetodologia()">
            <option selected="selected" [ngValue]="null">Selecione a metodologia</option>
            <option *ngFor="let item of metodologias" [ngValue]="item.nomeMetodologia">
              {{
                  item.nomeMetodologia
              }}
            </option>
          </select>
          <label>Metodologia</label>
        </div>

        <div class="form-floating" *ngIf="dadosUrl?.canal !== 'H' && formLead.get('metodologia').value">
          <select class="form-select" formControlName="turno" (change)="selectTurno()">
            <option selected="selected" [ngValue]="null">Selecione o turno</option>
            <option *ngFor="let item of turnos" [ngValue]="item.nomeTurno">
              {{
        item.nomeTurno
              }}
            </option>
          </select>
          <label>Turno</label>
        </div>

        <div class="form-floating" *ngIf="formLead.get('turno').value">
          <select class="form-select" formControlName="campus" (change)="selectCampus()">
            <option selected="selected" [ngValue]="null">Selecione a unidade ou polo de interesse</option>
            <option *ngFor="let item of campus" [ngValue]="item.nomeCampus">
              {{ item.nomeCampus }}
            </option>
          </select>
          <label>Unidade ou polo de interesse</label>
        </div>

        <div class="form-floating" *ngIf="this.dadosUrl.canal === 'H'">
          <input type="tel" class="form-control" mask="0000" placeholder="Ano"
                 formControlName="anoConclusaoEnsinoMedio">
          <label>Ano de Conclusão do Ensino Médio *</label>
        </div>
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Código" maxlength="5" formControlName="captcha"
                 mask="AAAAA" (keyup)="toUpper('loginForm', $event)">
          <label>Digite o código ao lado *</label>
          <span class="captcha">{{ captcha }}</span>
        </div>

        <button type="submit" class="btn btn-blue-light" [disabled]="formLead.invalid || formLead.controls['captcha'].value !== captcha || disabledBotao">
          <app-loader class="small-dark" *ngIf="isLoading"></app-loader>
          <span *ngIf="!isLoading">
            {{
             formLead.valid &&
             formLead.get('cpfLead').valid &&
             formLead.get('cpfLead').value &&
             formLead.get('curso').value &&
             formLead.get('metodologia').value &&
             formLead.get('turno').value &&
             formLead.get('campus').value ?
             'CADASTRAR OPORTUNIDADE' : 'CADASTRAR LEAD'
            }}
          </span>
        </button>
        <!--<button (click)="logRequiredFields()">clicar</button>-->

      </div>
    </div>
  </form>
</div>

