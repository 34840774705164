import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CSVService {
  downloadCSV(array: any, nome: string) {
    const data = JSON.parse(JSON.stringify(array)); //realiza deep-copy do array

    const csvData = this.ConvertToCSV(data);
    const a = document.createElement('a');
    a.setAttribute('style', 'display: none;');
    document.body.appendChild(a);

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `${nome}.csv`;

    a.click();
    document.body.removeChild(a);
  }

  private ConvertToCSV(objArray: any): string {
    const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (const index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ';';
    }

    row = row.slice(0, -1);

    //append Label row with line break
    str += row + '\r\n';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line != '') line += ';';
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }
}
