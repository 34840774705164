export class Lead {
  constructor(data: Partial<Lead>) {
    Object.assign(this, data);
  }
  ID: number;
  idCampanha: number;
  idCampanhaLocalCaptura: number;
  idCampanhaQRCode: string;
  idUsuario: number;
  codigoPDV: string;
  nomeCampanha: string;
  marca: string;
  regional: string;
  nucleoHub: string;
  uf: string;
  cidade: string;
  bairro: any;
  localCapturaEscola: string;
  nomeLead: string;
  emailLead: string;
  telefoneLead: string;
  cpfLead: string;
  escolaridade: string;
  metodologia: string;
  indModalidade: string;
  codigoInstituicaoInteresse: string;
  idEscolaridade: number;
  idMetodologia: number;
  anoConclusaoEnsinoMedio: number;
  tipoCurso: string;
  codCurso: number;
  curso: string;
  codCampus: string;
  codCampusPai: string;
  campus: string;
  codTurno: string;
  turno: string;
  canal: string;
  empresa: string;
  idSalesforce: string;
  canalSalesforce: string;
  indicadorQrCode: boolean;
}
