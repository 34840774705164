// db.ts
import Dexie, { Table } from 'dexie';
import {
  CampanhaGerais,
  Escolaridade,
  LocalCampanha,
  Metodologia,
  SKU,
} from '@models/Gerais';
import { Lead } from '../models/Lead';

export class AppDB extends Dexie {
  campanhas!: Table<CampanhaGerais, number>;
  metodologias!: Table<Metodologia, number>;
  escolaridades!: Table<Escolaridade, number>;
  skus!: Table<SKU, number>;
  locaisCampanha!: Table<LocalCampanha, number>;
  leads!: Table<Lead, number>;
  constructor() {
    super('captaleads2');
    this.version(2).stores({
      //Sempre que o esquema for atualizado, tem que acrescentar 1 à versão
      campanhas: '&idCampanha',
      metodologias: '&chave',
      escolaridades: '&chave',
      skus: '&chave',
      locaisCampanha: '&chave,idCampanha',
      leads: '++ID',
    });
  }
}

export const db = new AppDB();
