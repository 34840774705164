import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteGuardService } from '@services/route-guard.service';
import { LoginComponent } from '@pages/login/login.component';
import { HomeComponent } from '@pages/home/home.component';
import { QrCaptacaoPage } from '@pages/qr-captacao/qr-captacao.page';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'qr-captacao', component: QrCaptacaoPage },
  //{ path: 'qr-captacao/:local/:idUsuario', component: QrCaptacaoPage },
  {
    path: '',
    component: HomeComponent,
    canActivate: [RouteGuardService],
    children: [
      { path: '', component: HomeComponent },
      /*{ path: 'cadastro', component: ItemComponent }*/
    ],
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
