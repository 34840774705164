import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Lead } from '@models/Lead';
import { db } from './db';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { BaseList } from '@models/Base';
import { RetornoCadastroLead } from '@models/RetornoCadastroLead';
import { ContadorLead } from '../models/ContadorLead';
import { CampanhaPorId } from '../models/Campanha';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class LeadService implements OnDestroy {
  baseURL = `${environment.baseUrl}`;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  contadorCadastroTotalSubject: Subject<number>;
  contadorAguardandoSubject: Subject<number>;
  sincronizadoOnline: Subject<void> = new Subject<void>();
  contadorCadastroTotal: number;
  contadorAguardando: number;

  online: boolean = navigator.onLine;
  emitter;

  constructor(private http: HttpClient, private _toastr: ToastrService) {
    //Controle de online e offline
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(
      this.onlineEvent.subscribe(() => {
        this.online = true;
        console.log('Trocou status para Online');
        this.sincronizar();
      })
    );

    this.subscriptions.push(
      this.offlineEvent.subscribe(() => {
        this.online = false;
        console.log('Trocou status para Offline');
      })
    );

    this.contadorAguardando = localStorage['contadorAguardando'] ?? 0;
    this.contadorCadastroTotal = localStorage['contadorCadastroTotal'] ?? 0;
    this.contadorCadastroTotalSubject = new Subject();
    this.contadorAguardandoSubject = new Subject();

    this.contadorCadastroTotalSubject = new Subject();
    this.contadorAguardandoSubject = new Subject();

    this.contadorCadastroTotalSubject.next(this.contadorCadastroTotal);
    this.contadorAguardandoSubject.next(this.contadorAguardando);
  }

  // Adicionar Lead
  postLead(lead: Lead, sync = false) {
    const me = this;
    return new Promise((resolve, reject) => {
      if (me.online) {
        //Por enquanto não usamos o resultado retornado, pois fazer o load novamente é feito manualmente pelo usuário
        const resultado = me.http
          .post<BaseList<RetornoCadastroLead>>(`${me.baseURL}Registrar_Lead`, lead)
          .subscribe(
            response => {
              console.log('Lead gravado');
              resolve(true);
              db.leads.delete(lead.ID);
              me.atualizarAguardando();
            },
            error => {
              if (!sync) {
                console.log('Offline, gravaremos o lead posteriormente');
                console.log(error);
                db.leads.add(lead);
                me.incrementarTotal(lead.idCampanha);
              } else {
                this._toastr.error('Não foi possível sincronizar, tente mais tarde');
              }
              me.atualizarAguardando();
              resolve(false);
            }
          );
      } else {
        if (!sync) {
          console.log('Offline, gravaremos o lead posteriormente');
          db.leads.add(lead);
          me.incrementarTotal(lead.idCampanha);
        } else {
          this._toastr.error('Não foi possível sincronizar, tente mais tarde');
        }
        me.atualizarAguardando();
        resolve(false);
      }
    });
  }

  postLeadQR(lead: Lead) {
    return this.http.post<BaseList<RetornoCadastroLead>>(`${this.baseURL}Registrar_Lead_QR`, lead);
  }

  async sincronizar() {
    const itens = await db.leads.toArray();
    this.contadorAguardando = itens.length;
    const metodologias = await db.metodologias.toArray();
    const escolaridades = await db.escolaridades.toArray();
    if (itens.length > 0) {
      for (let i = 0; i < itens.length; i++) {
        //const dados = {
        //  // Obrigatórios
        //  ...itens[i],
        //  marca: campanha.marcaCampanhaUsuario,
        //  idCampanha: campanha.idCampanha,
        //  idUsuario: 'idUsuario' in localStorage ? parseInt(localStorage.getItem('idUsuario')) : 1,
        //  idMetodologia: metodologias.filter(x => {
        //    return x.nomeMetodologia == itens[i].metodologia;
        //  })[0].chave,
        //  idEscolaridade: escolaridades.filter(x => {
        //  return x.nomeEscolaridade == itens[i].escolaridade;
        //})[0].chave,
        //};
        itens[i].idMetodologia = metodologias.filter(x => {
          return x.nomeMetodologia == itens[i].metodologia;
        })[0]?.chave;
        itens[i].idEscolaridade = escolaridades.filter(x => {
          return x.nomeEscolaridade == itens[i].escolaridade;
        })[0]?.chave;
        this.postLead(itens[i], true).then(exito => {
          this.sincronizadoOnline.next();
        });
      }
    } else {
      this.atualizarAguardando();
    }
  }

  getContadores() {
    return { total: this.contadorCadastroTotalSubject, aguardando: this.contadorAguardandoSubject };
  }

  getLeadsRegistrados() {
    return this.http.get<BaseList<ContadorLead>>(this.baseURL + 'Contar_Usuario_Lead');
  }

  atualizarContadores() {
    this.contadorAguardandoSubject.next(this.contadorAguardando);
  }

  incrementarTotal(idCampanha: number) {
    this.atualizarAguardando();
    //obj.foreach(x => {
    //  if (x.idCampanha == idCampanha) {
    //    x.quantidadeLead++;
    //  }
    //})
  }

  async atualizarAguardando() {
    let obj = [];
    obj = JSON.parse(localStorage.getItem('contadorAguardando'));

    if (obj) {
      obj.forEach(o => {
        o.quantidadeLead = 0;
      });
      const leads = await db.leads.toArray();
      if (leads?.length > 0) {
        leads.forEach(l => {
          if (
            obj.filter(x => {
              return x.idCampanha == l.idCampanha;
            }).length == 0
          ) {
            obj.push(new ContadorLead(l.idCampanha, 1));
          } else {
            obj.forEach(o => {
              if (o.idCampanha == l.idCampanha) o.quantidadeLead++;
            });
          }
        });
      }
      localStorage.setItem('contadorAguardando', JSON.stringify(obj));
      const storageChangeEvent = new StorageEvent('storage', {
        key: 'myKey',
        oldValue: 'oldValue',
        newValue: 'newValue',
        storageArea: sessionStorage,
      });

      window.dispatchEvent(storageChangeEvent);
    }
  }

  //Temos que limpara as subscriptions quando este objeto for destruído
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
