<!-- loader iduqs -->
<app-loader-yduqs *ngIf="loadingYduqs" [@loaderYduqs]></app-loader-yduqs>

<div class="login" *ngIf="!loadingYduqs" [@EaseOutAnimation]
  [ngClass]="{'innit' : !usuarioCadastrado}">

  <div class="box-logo">
    <img class="img-logo" src="assets/images/logo.png">
    <div class="box-img-cata"
      [ngClass]="{'innit' : !usuarioCadastrado}">
      <img class="person" src="assets/images/person-cicle.svg">
      <img class="cata-leads" src="assets/images/cata-leads.svg">
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <ng-container *ngIf="usuarioCadastrado">
          <form [formGroup]="loginForm" (ngSubmit)="onLogin()" [@EaseOutAnimation]>
            <h1>Acesso</h1>
            <div class="form-floating">
              <input type="email" class="form-control" placeholder="E-mail" formControlName="email">
              <label>E-mail</label>
            </div>

            <div class="form-floating">
              <input type="tel" class="form-control" placeholder="CPF" formControlName="cpf" mask="000.000.000-00">
              <label>CPF</label>
            </div>

            <div class="form-floating">
              <input type="text" class="form-control" placeholder="Código" maxlength="5" formControlName="captcha"
                mask="AAAAA" (keyup)="toUpper('loginForm', $event)">
              <label>Digite o código ao lado *</label>
              <span class="captcha">{{ captcha }}</span>
            </div>

            <button type="submit" class="btn btn-blue-light"
              [disabled]="loginForm.invalid || (loginForm.controls['captcha'].value !== captcha)">
              <app-loader class="small-dark" *ngIf="isLoading"></app-loader>
              <span *ngIf="!isLoading">Entrar</span>
            </button>
          </form>
        </ng-container>
        
        <!-- Seleção inicial: Usuário cadastrado x Primeiro acesso x Confirmação de cadastro -->
        <ng-container *ngIf="!usuarioCadastrado">
          <button type="button" class="btn btn-blue-light" (click)="etapa('usuarioCadastrado')">
            Acessar</button>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- Modal privacidade -->
<ng-container *ngIf="privacidade">
  <app-privacidade (aceitarPrivacidade)="aceitarPrivacidade($event)"></app-privacidade>
</ng-container>

<ng-container *ngIf="!usuarioCadastrado">
  <p class="version">{{ appVersion }}</p>
</ng-container>
