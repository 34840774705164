<!-- Routes -->
<ng-container *ngIf="animation">
  <router-outlet [@EaseOutAnimation]></router-outlet>
</ng-container>


<!-- Mensagem de atualização -->
<ng-container *ngIf="novaVersaoDisponivel">
  <button type="button" class="btn btn-update" (click)="confirmarAtualizacao()">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" /><path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" /></svg>
    <span>Nova versão disponível!<br> Atualizar.</span>
  </button>
</ng-container>

<!-- Modal para atualização de versão. -->
<dialog class="dialog" #Atualizacao>
  <div class="dialog-header">
    <h1>Atenção!</h1>
  </div>

  <div class="dialog-content">
    <p>Se existir algum lead em processo de cadastro, finalize antes de atualizar!</p>
  </div>

  <div class="dialog-buttons">
    <button type="button" class="btn" (click)="atualizar()">Atualizar</button>
    <button type="button" class="btn" (click)="cancelar()">Cancelar</button>
  </div>
</dialog>
