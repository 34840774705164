import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subscription } from 'dexie';
import { EaseOutAnimation, loaderYduqs } from 'src/app/services/animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [EaseOutAnimation, loaderYduqs],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('Atualizacao') atualizacao: ElementRef;

  animation = true;

  novaVersaoDisponivel = false;
  subscriptions: Subscription[] = [];

  constructor(private updates: SwUpdate) {}

  ngOnInit(): void {
    // Verifica atualização
    this.subscriptions.push(
      this.updates.available.subscribe(() => {
        this.novaVersaoDisponivel = true;
      })
    );

    // DEBUG!!!
    //this.novaVersaoDisponivel = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // Exibe modal de confirmação
  confirmarAtualizacao() {
    this.atualizacao.nativeElement.showModal();
  }

  // Funcionalidade de update de versão (PWA)
  atualizar() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }

  // Cancela
  cancelar() {
    this.atualizacao.nativeElement.close();
  }
}
