<app-loader-yduqs *ngIf="loadingYduqs"></app-loader-yduqs>
<div class="container">
  <div class="navbar">
    <div class="box-logo">
      <ng-container *ngIf="!editarCampanha && !registrarLead">
        <img class="img-logo" src="assets/images/logo.png">
      </ng-container>
      <ng-container *ngIf="editarCampanha || registrarLead">
        <div class="img-left">
          <img class="img-logo" style="width:38px!important ;" src="assets/images/left.svg" (click)="etapa('reset')">
          Voltar
        </div>
      </ng-container>

      <div class="btn-group" dropdown>
        <button class="box-img-cata dropdown" type="button" style="background: transparent; border: none;"
                dropdownToggle>
          <img class="person" src="assets/images/person-cicle.svg">
          <img class="cata-leads" src="assets/images/cata-leads.svg">

          <ul class="toolbar" *dropdownMenu>
            <button type="button" class="btn btn-small voltar" (click)="exibirConfirmacao('logOut')">SAIR</button>
          </ul>
        </button>
      </div>
    </div>
  </div>
</div>


<main id="Topo">

  <!-- Resumo da campanha -->
  <ng-container *ngIf="!editarCampanha && !registrarLead">

    <div class="footer">
      <p class="version">{{appVersion}}</p>
    </div>

    <div class="container">
      <div class="box-perfil">
        <div class="dados-perfil">
          <p>Olá,</p>
          <h1> {{nomeUsuario}} </h1>
        </div>
      </div>

      <div class="campanha">
        <div class="box-title-campanha">
          <div class="length-campanha">
            <h1 class="title campanha">Campanhas</h1>
            <ng-container *ngIf="dadosGerais?.campanhas.length == 1">
              <span>{{ dadosGerais?.campanhas.length }} ativa</span>
            </ng-container>
            <ng-container *ngIf="dadosGerais?.campanhas.length > 1">
              <span>{{ dadosGerais?.campanhas.length }} ativas</span>
            </ng-container>
          </div>

          <div class="controler-atualizar">
            <img class="grid" src="/assets/images/horizontal.svg" alt="grid-horizontal"
                 [ngClass]="{'active' : grid === 'horizontal'}" (click)="onGrid('horizontal')">
            <img class="grid" src="/assets/images/vertical.svg" alt="grid-vertical"
                 [ngClass]="{'active' : grid === 'vertical'}" (click)="onGrid('vertical')">
            <button type="button" class="btn btn-atualizar" (click)="exibirConfirmacao('atualizarDados')"
                    tooltip="Atualizar Dados">
              <img src="/assets/images/reload.svg" alt="">
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- carrosel -->
    <ng-container *ngIf="dadosGerais?.campanhas.length > 0">
      <div class="carrosel" [ngClass]="{'on-vertical' : grid === 'vertical'}">
        <app-loader *ngIf="dadosGerais?.campanhas?.length == 0"></app-loader>
        <div class="efeito-rigth"></div>
        <div class="box-cards" *ngIf="dadosGerais" #boxCards [ngClass]="{'on-vertical' : grid === 'vertical'}">
          <!-- ngfor em campanhas disponíveis-->
          <div class="card" *ngFor="let item of dadosGerais.campanhas; let i = index;"
               [ngClass]="{'enem': item?.canal === 'E', 'highscool' : item?.canal === 'H','trade' : item?.canal === 'T', 'on-vertical' : grid === 'vertical'}">

            <div class="box-title-controls" [ngClass]="{'on-vertical' : grid === 'vertical'}">
              <div class="box-title">
                <h1 class="title-card">{{ item?.descricaoCampanha }}</h1>
                <!-- <p class="date">{{ item?.inicio | date:'dd \'de\' MMMM' }}</p> -->
                <p class="date">{{ item?.inicio | date:'dd/MM/yy' }} a {{ item?.termino | date:'dd/MM/yy' }}</p>
                <span>{{ item?.marcaCampanhaUsuario }}</span>

                <ng-container *ngIf="grid === 'horizontal'">
                  <div class="locale">
                    <img src="/assets/images/locale.svg" alt="">
                    {{item?.regionalCampanhaUsuario}}
                  </div>
                </ng-container>

              </div>

              <div class="controlers">
                <!-- Cadastrar lead -->
                <button type="button" class="btn btn-dark" (click)="cadastrarLead(item)">Cadastrar lead</button>
                <!-- Gerar QR -->
                <button type="button" class="btn btn-blue-strong" (click)="modalQrcode('open', item)">
                  Gerar QR
                  Code
                </button>
              </div>
            </div>

            <div class="resultados" [ngClass]="{'on-vertical' : grid === 'vertical'}">
              <p class="bd" [ngClass]="{'on-vertical' : grid === 'vertical'}">Leads registrados:<span>{{ item?.leadsRegistrados }}</span></p>
              <p>Não enviados: <span>{{ item?.leadsNaoEnviados }}</span></p>
            </div>

            <div class="footer-card" [ngClass]="{'on-vertical' : grid === 'vertical'}">
              <app-loader *ngIf="isLoading"></app-loader>
              <button *ngIf="!isLoading" type="button" class="btn btn-atualizar" (click)="sincronizarLeads()">Atualizar</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="dadosGerais?.campanhas.length == 0">

      <p style="font-size: 14px; color: #FFB800;">Não existem campanhas ativas, já fez a atualização da base de dados?</p>

    </ng-container>
  </ng-container>


  <!-- Dados da Campanha -->
  <ng-container *ngIf="editarCampanha">
    <!-- Desabilitado na versão 2.0 -->
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div id="Formulario" class="campanha">
            <button type="button" class="btn btn-atualizar" (click)="exibirConfirmacao('atualizarDados')">
              Atualizar
              dados gerais
            </button>

            <form [formGroup]="formCampanha" (ngSubmit)="exibirConfirmacao('editarCampanha')">
              <div class="row">
                <div class="col-12">
                  <div class="form-floating">
                    <select class="form-select" formControlName="acao" (change)="selectAcao()">
                      <option selected="selected" value="null">Selecione o evento</option>
                      <option *ngFor="let item of dadosGerais.campanhas" [value]="item.descricaoCampanha">
                        {{
                        item.descricaoCampanha
                        }}
                      </option>
                    </select>
                    <label>Ação *</label>
                  </div>

                  <div class="form-floating">
                    <input class="form-select" formControlName="estado" readonly />
                    <label>Estado de captura *</label>
                  </div>

                  <div class="form-floating">
                    <input class="form-select" formControlName="marca" readonly />
                    <label>Marca *</label>
                  </div>

                  <div class="form-floating">
                    <input class="form-select" formControlName="regional" readonly />

                    <label>Regional *</label>
                  </div>

                  <div class="form-floating">
                    <select class="form-select" formControlName="municipio">
                      <option selected="selected" value="null">Selecione a cidade</option>
                      <option *ngFor="let item of municipios" [value]="item">{{ item }}</option>
                    </select>
                    <label>Cidade *</label>
                  </div>

                  <div class="form-floating" *ngIf="canal !== 'H'">
                    <select class="form-select" formControlName="bairro" (change)="selectBairro()">
                      <option selected="selected" value="null">Selecione o bairro</option>
                      <option *ngFor="let item of bairros" [value]="item">{{ item }}</option>
                    </select>
                    <label>Bairro *</label>
                  </div>

                  <div class="form-floating">
                    <select class="form-select" formControlName="local">
                      <option selected="selected" value="null">Selecione o local de captura</option>
                      <option *ngFor="let item of locaisCaptura" [value]="item">{{ item }}</option>
                    </select>
                    <label>{{canal === 'H' ? 'Escola' : 'Local de captura'}} *</label>
                  </div>

                  <button type="submit" class="btn btn-blue-light" [disabled]="formCampanha.invalid || isLoading">
                    <app-loader class="small-dark" *ngIf="isLoading"></app-loader>
                    <span *ngIf="!isLoading">SALVAR</span>
                  </button>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Registro de Lead -->
  <ng-container *ngIf="registrarLead">
    <!-- em desenvolvimento - formulario no component -->
    <!-- <app-form-campanha></app-form-campanha> -->
    <div class="container">
      <div class="row">
        <div class="col-12">

          <div class="box-faixa">
            <div class="faixa">
              <div class="box-title">
                <h1 class="text-start">{{ dadosCampanhaPorId.descricaoCampanha }}</h1>
                <p class="date">{{ dadosCampanhaPorId?.inicio | date:'dd \'de\' MMMM' }}</p>
              </div>
            </div>
          </div>

          <div id="Formulario" class="campanha">

            <form [formGroup]="formLead" (ngSubmit)="salvarLead()">
              <div class="row">
                <div class="col-12">

                  <!-- se for High Scool, não aparecerá o select de local captura -->
                  <ng-container *ngIf="dadosCampanhaPorId.canal !== 'H'">
                    <h6>Selecione o local da captura</h6>
                    <div class="form-floating mb-5">
                      <select class="form-select" formControlName="localCaptura" (change)="setBairro()">
                        <option selected="selected" value="null">Selecione o local</option>
                        <option *ngFor="let item of getLocaisByCampanhaId(dadosCampanhaPorId.idCampanha)"
                                [ngValue]="item.chave">
                          {{
                          item.bairro
                          }} - {{ item.localCaptura }}
                        </option>
                      </select>
                      <label>Local da captura*</label>
                    </div>
                  </ng-container>

                  <div class="form-floating">
                    <input type="text" class="form-control" placeholder="Nome" formControlName="nomeLead">
                    <label>Nome *</label>
                  </div>

                  <div class="form-floating">
                    <input type="email" class="form-control" placeholder="E-mail" formControlName="emailLead">
                    <label>E-mail *</label>
                  </div>

                  <div class="form-floating">
                    <input type="tel" class="form-control" mask="00||(00) 00000-0000||(00) 0000-0000"
                           placeholder="Telefone" formControlName="telefoneLead">
                    <label>Telefone *</label>
                  </div>

                  <div class="form-floating">
                    <input type="tel" class="form-control" mask="000.000.000-00" placeholder="CPF"
                           formControlName="cpfLead">
                    <label>CPF</label>
                  </div>

                  <div class="form-floating" *ngIf="canal === 'T'">
                    <input type="text" class="form-control" placeholder="Empresa" formControlName="empresa">
                    <label>Empresa ou escola</label>
                  </div>

                  <div class="form-floating">
                    <select class="form-select" formControlName="escolaridade">
                      <option selected="selected" [ngValue]="null">Selecione a escolaridade</option>
                      <option *ngFor="let item of dadosGerais.escolaridades" [ngValue]="item.nomeEscolaridade">
                        {{
                        item.nomeEscolaridade
                        }}
                      </option>
                    </select>
                    <label>Escolaridade</label>
                  </div>

                  <!--<div class="form-floating" *ngIf="canal !== 'E' && canal !== 'H'">
                    <select class="form-select" formControlName="tipoCurso" (change)="selectTipoCurso()">
                      <option selected="selected" value="null">Selecione o tipo de curso</option>
                      <option *ngFor="let item of tiposCursos">{{ item }}</option>
                    </select>
                    <label>Tipo de curso *</label>
                  </div>-->

                  <div class="form-floating">
                    <select class="form-select" formControlName="curso" (change)="selectCurso()">
                      <option selected="selected" [ngValue]="null">Selecione o curso</option>
                      <option *ngFor="let item of cursos" [ngValue]="item.nomeCurso">{{ item.nomeCurso }}</option>
                    </select>
                    <label>Curso de interesse *</label>
                  </div>

                  <div class="form-floating" *ngIf="canal !== 'H' && cursoSelecionado">
                    <select class="form-select" formControlName="metodologia" (change)="selectMetodologia()">
                      <option selected="selected" [ngValue]="null">Selecione a metodologia</option>
                      <option *ngFor="let item of getMetodologiasPorCurso(cursoSelecionado)" [ngValue]="item.modalidade">
                        {{
                        item.modalidade
                        }}
                      </option>
                    </select>
                    <label>Metodologia</label>
                  </div>

                  <div class="form-floating" *ngIf="canal !== 'H' && formLead.get('metodologia').value">
                    <select class="form-select" formControlName="turno" (change)="selectTurno()">
                      <option selected="selected" [ngValue]="null">Selecione o turno</option>
                      <option *ngFor="let item of getTurnosPorCurso(cursoSelecionado, modalidadeSelecionada)" [ngValue]="item.nomeTurno">
                        {{
                        item.nomeTurno
                        }}
                      </option>
                    </select>
                    <label>Turno</label>
                  </div>

                  <div class="form-floating" *ngIf="canal !== 'H' && formLead.get('turno').value">
                    <select class="form-select" formControlName="campus" (change)="selectCampus()">
                      <option selected="selected" [ngValue]="null">Selecione a unidade ou polo de interesse</option>
                      <option *ngFor="let item of campus" [ngValue]="item.nomeCampus">
                        {{
                      item.nomeCampus
                        }}
                      </option>
                    </select>
                    <label>Unidade ou polo de interesse </label>
                  </div>

                  <div class="form-floating" *ngIf="canal==='H'">
                    <input type="tel" class="form-control" mask="0000" placeholder="Ano de Conclusão"
                           formControlName="anoConclusaoEnsinoMedio">
                    <label>Ano de Conclusão do Ensino Médio *</label>
                  </div>

                  <button type="submit" class="btn btn-blue-light" [disabled]="formLead.invalid || isLoading">
                    <app-loader class="small-dark" *ngIf="salvando"></app-loader>
                    <span *ngIf="!salvando">
                      {{
                        formLead.valid &&
                        formLead.get('cpfLead').valid &&
                        formLead.get('cpfLead').value &&
                        formLead.get('curso').value &&
                        formLead.get('metodologia').value &&
                        formLead.get('turno').value &&
                        formLead.get('campus').value ?
                        'CADASTRAR OPORTUNIDADE' : 'CADASTRAR LEAD'
                      }}
                    </span>
                  </button>
                  <!--<button *ngIf="formLead.invalid" (click)="logRequiredFields()">Mostrar</button>-->

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</main>

<!----------------------------------------------------------------------------
  TEMPLATES
----------------------------------------------------------------------------->
<ng-container *ngIf="exibeConfirmacao">
  <div class="container">
    <section class="modal-container">
      <article class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <h1 class="text-start">Confirmar</h1>
            </div>

            <div class="col-12 mb-3">
              <p class="mensagem">{{ mensagem }}</p>
            </div>
          </div>
        </div>
        <div class="btn-container">
          <button type="button" class="btn-cancel"
                  (click)="exibeConfirmacao = false; isLoading = false;">
            CANCELAR
          </button>
          <button type="button" class="btn-confirm" (click)="dialogoCallBack()" [disabled]="salvando">
            <app-loader class="small" *ngIf="salvando"></app-loader>
            <span *ngIf="!salvando">CONFIRMAR</span>
          </button>
        </div>
      </article>
    </section>
  </div>
</ng-container>


<ng-container *ngIf="isModalQrcode">
  <div class="container">
    <div class="modal-qrcode">
      <div class="box-modal">
        <!-- close -->
        <button type="button" class="close" (click)="modalQrcode('exit', null)">X</button>


        <ng-container *ngIf="dadosCampanhaPorId?.canal === 'H'">
          <h1>High School</h1>
        </ng-container>
        <!-- se for High Scool, não aparecerá o select de local captura -->
        <ng-container *ngIf="dadosCampanhaPorId.canal !== 'H'">
          <h6>Selecione o local da captura</h6>
          <div class="form-floating">
            <select class="form-select" [(ngModel)]="selectedLocalCaptura" (change)="generateQRCode('')">
              <option selected="selected" value="null">Selecione o local</option>
              <option *ngFor="let item of getLocaisByCampanhaId(dadosCampanhaPorId.idCampanha)"
                      [ngValue]="item.chave">
                {{
                item.bairro
                }} - {{ item.localCaptura }}
              </option>
            </select>
            <label>Local da captura*</label>
          </div>
        </ng-container>

        <ng-container *ngIf="selectedLocalCaptura !== null && qrCode || dadosCampanhaPorId.canal === 'H' && qrCode">
          <qrcode (qrCodeURL)="onChangeURL($event)" [qrdata]="qrCode" [errorCorrectionLevel]="'M'">
          </qrcode>

          <button type="button" class="btn btn-compartilhar" (click)="shareImage()">
            <img src="/assets/images/compartilhar.svg" width="25px" alt="">
            <a [href]="qrCodeDownloadLink" download="qrcode">Compartilhar</a>
          </button>
        </ng-container>




      </div>
    </div>
  </div>
</ng-container>

