import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { registerLocaleData } from '@angular/common';
import ptBR from '@angular/common/locales/pt';

import { AuthInterceptor } from '@services/auth.interceptor';
import { AppRoutingModule } from './app-routing.module';

import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { LoaderComponent } from '@components/loader/loader.component';
import { LoginComponent } from '@pages/login/login.component';
import { HomeComponent } from '@pages/home/home.component';
import { PrivacidadeComponent } from './components/privacidade/privacidade.component';

// NGX Toastr
import { ToastrModule } from 'ngx-toastr';

// NGX Mask
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';

// QR Code
import { QRCodeModule } from 'angularx-qrcode';

// Multiselect
import { LoaderYduqsComponent } from './components/loader-yduqs/loader-yduqs.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormCampanhaPage } from './pages/home/form-campanha/form-campanha.page';

// NGX Bootstrap
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { QrCaptacaoPage } from './pages/qr-captacao/qr-captacao.page';

// Configs
// Locale
registerLocaleData(ptBR, 'pt-BR');

const configMask: Partial<IConfig> = {
  validation: false,
};

const configToastr = {
  timeOut: 10000,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  closeButton: true,
};

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    LoginComponent,
    HomeComponent,
    PrivacidadeComponent,
    LoaderYduqsComponent,
    FormCampanhaPage,
    QrCaptacaoPage,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ToastrModule.forRoot(configToastr),
    NgxMaskDirective,
    NgxMaskPipe,
    BsDropdownModule.forRoot(),
    QRCodeModule,
    TypeaheadModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    provideEnvironmentNgxMask(configMask),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
