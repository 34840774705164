<ng-container *ngIf="registrarLead">
    <div class="col-12">
      <div id="Formulario" class="campanha">
        <div class="controles">
          <h3>Registrar lead</h3>
          <button type="button" class="btn btn-blue-strong" (click)="etapa('voltar')">Voltar</button>
        </div>

        <form [formGroup]="formLead" (ngSubmit)="exibirConfirmacao('registrarLead')">
          <div class="row">
            <div class="col-12">
              <div class="form-floating">
                <input type="text" class="form-control" placeholder="Nome" formControlName="nomeLead">
                <label>Nome *</label>
              </div>

              <div class="form-floating">
                <input type="email" class="form-control" placeholder="E-mail" formControlName="emailLead">
                <label>E-mail *</label>
              </div>

              <div class="form-floating">
                <input type="text" class="form-control" mask="(00) 00000-0000 || (00) 0000-0000"
                  placeholder="Telefone" formControlName="telefoneLead">
                <label>Telefone *</label>
              </div>

              <div class="form-floating">
                <input type="tel" class="form-control" mask="000.000.000-00" placeholder="CPF"
                  formControlName="cpfLead">
                <label>CPF *</label>
              </div>

              <div class="form-floating" *ngIf="canal === 'T'">
                <input type="text" class="form-control" placeholder="Empresa" formControlName="empresa">
                <label>Empresa *</label>
              </div>

              <div class="form-floating">
                <select class="form-select" formControlName="escolaridade">
                  <option selected="selected" value="null">Selecione a escolaridade</option>
                  <option *ngFor="let item of dadosGerais.escolaridades" [ngValue]="item.nomeEscolaridade">{{
                    item.nomeEscolaridade }}</option>
                </select>
                <label>Escolaridade </label>
              </div>

              <!--<div class="form-floating" *ngIf="canal !== 'E'">
                <select class="form-select" formControlName="tipoCurso" >
                  <option selected="selected" value="null">Selecione o tipo de curso</option>
                  <option *ngFor="let item of tiposCursos">{{ item }}</option>
                </select>
                <label>Tipo de curso *</label>
              </div>-->

              <div class="form-floating">
                <select class="form-select" formControlName="curso" >
                  <option selected="selected" value="null">Selecione o curso</option>
                  <option *ngFor="let item of cursos" [ngValue]="item.nomeCurso">{{ item.nomeCurso }}</option>
                </select>
                <label>Curso de interesse *</label>
              </div>

              <!--<div class="form-floating">
<input type="text" class="form-control" placeholder="Unidade de interesse" formControlName="unidadeInteresse">
<label>Unidade de interesse</label>
</div>-->

              <div class="form-floating">
                <select class="form-select" formControlName="metodologia" (change)="selectMetodologia()">
                  <option selected="selected" value="null">Selecione a metodologia</option>
                  <option *ngFor="let item of dadosGerais.metodologias" [ngValue]="item.nomeMetodologia">{{
                    item.nomeMetodologia }}</option>
                </select>
                <label>Metodologia</label>
              </div>

              <div class="form-floating" *ngIf="canal==='T' && campus?.length > 0">
                <label class="input-label mb-4">Unidade ou Polo</label>
                <div class="autocomplete">
                  <input type="text" class="form-control" formControlName="campus" [typeahead]="campus"
                    typeaheadOptionField="nomeCampus" [typeaheadMinLength]="0" [typeaheadSelectFirstItem]="false"
                    [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="5">
                </div>
              </div>
              <div class="form-floating" *ngIf="canal==='H'">
                <input type="tel" class="form-control" mask="0000" placeholder="CPF"
                  formControlName="anoConclusaoEnsinoMedio">
                <label>Ano de Conclusão do Ensino Médio *</label>
              </div>

              <button type="submit" class="btn btn-alt mt-3" [disabled]="formLead.invalid || isLoading">
                <app-loader class="small-dark" *ngIf="isLoading"></app-loader>
                <span *ngIf="!isLoading">SALVAR</span>
              </button>

            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
